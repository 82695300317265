
.chatContainer {
    background-color: #eceef4;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); */
    z-index: 10;
    backdrop-filter: blur(2px);
    border-right: solid 1px #e4e4e4;
  }
  
  .chatHeader {
    padding-left: 20px;
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;
    backdrop-filter: blur(2px);
    color: #f1faee;
    background-color: rgb(255, 255, 255, 0.0);
  }

/* Chat log */
.chat-log {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px;
    padding-bottom: 600px;
    width: 80vw;
    max-width: 500px;
    border-radius: 8px;
    height: 500px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  
  .toggleIM {
    background-color: #fff;  
    padding: 5px 10px;
    display: inline-block;
    float: right;
    border: solid 0px #b3b3b3;
    color: #636e72;
    border-radius: 20px;
    margin-right: 15px;
    margin-top: 14px;
  }
/* Message wrapper (which includes the role label and message bubble) */
.message-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 60%;
  }
  
    .message-wrapper .role-label {
      margin: 0 10px;
    }
  
      .inner-mono .message-wrapper .role-label {
        color: white;
      }
  
  /* Message bubble */
  .message-bubble {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 4px;
    font-size: 16px;
    word-wrap: break-word;
    clear: both;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  }
    .message-bubble .toggle-writing-message {
      display: block;
      background-color: #ffffff;
      padding: 10px 15px;
      border-radius: 15px;
      text-align: center;
      color: #555555;
      margin-bottom: 15px;
    }
  
    .toggle-writing-message:hover {
      cursor: pointer;
      color: #1ab5b5;
      transition: all 0.3s;
    }
  
  .role-label {
    color: #747474;
  }
  
  /* System message */
  .system {
    align-self: flex-end;
  }
  
    .system .message-bubble {
      background-color: #4b8f62;
      color: #fff;
    }
  
    .system .role-label {
      align-self: flex-end;
    }
  
  /* User message */
  .user {
    align-self: flex-end;
  }
  
    .user .message-bubble {
      background-color: #0a3d62;
    }
      .user .message-bubble p {
        color: #fff;
      }
  
    .user .role-label {
      align-self: flex-end;
    }
      .inner-mono .user .message-bubble {
        background-color: #b300ff;
      }
  
  /* Bot message */
  .assistant {
    align-self: flex-start;
  }
  
    .assistant .message-bubble {
      background-color: #fff;
      color: #000;
      backdrop-filter: blur(2px);
    }
      .inner-mono .assistant .message-bubble {
        background-color: #f0ceff;
      }
  
  /* Textbox and button */
  .textbox-container {
    margin: 0px 20px;
    position: sticky;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: none;
    border: none;
  }
  
  .textbox {
    background-color: #fff;
    flex: 1;
    font-size: 16px;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #e4e4e4;
    margin-right: 10px;
    height: fit-content;
    min-height: 5em;
    width: 420px;
    margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif
  }
    .textbox.noEdit {
      background-color: #5193bc;
    }
  
    .textbox.noEdit:focus {
      outline: none;
    }
  
    .textbox-container .mic-btn {
      vertical-align: middle;
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: #fff;
      margin-left: 10px;
    }
      .mic-btn.listening {
        background-color: #e63946;
      }
  
      .mic-btn.not-listening {
        background-color: #34ace0;
        color: #ffffff;
      }
        .mic-btn.not-listening.noEdit {
          background-color: #5193bc;
          color: #8dc3e5;
        }
  
  .send-button {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #0084ff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .inner-mono {
    background-color: #dedede;
  }
  
    .inner-mono .chat-log {
      overflow-y: scroll;
    }