/* App container */
body {
  /* background-color: #f1faee; */
  background: #fff;
  /* background: linear-gradient(51deg, rgba(212,214,249,1) 0%, rgba(204,222,245,1) 48%, rgba(250,225,242,1) 100%); */
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 1.1em;
  color: #1d3557;
}

p, ul {
  margin: 0px;
}

li {
  margin: 15px 0px;
}

h1, h2, h3, h4, p {
  color: #1d3557;
}

input[type="text"] {
  width: 230px;
  padding: 8px;
  margin: 10px 0px;
  font-size: 1em;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
}

.right-aligned {
  text-align: right;
  float: right;
}

.flex-col {
  flex-direction: column;
}

.gray {
  color: #656f78;
  font-weight: 600;
}

.light-gray-bg {
  background-color: #eceef4;
}

.subtle-gray {
  color: #b6bcc5;
}

.clickable {
  text-decoration: underline;
  color: #457b9d;
}

  .clickable:hover {
    cursor: pointer;
    color: #1e3799;
  }

.secondary-btn svg {
  margin-right: 10px;
  margin-left: -5px;
}

.primary-btn {
  display: inline-block;
  border: none;
  text-align: center;
  padding: 7px 20px;
  margin-top: 15px;
  background-color: #34ace0;
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.2s ease;
}
  .primary-btn:hover {
    cursor: pointer;
    background-color: #1981ae;
  }

.secondary-btn {
  display: inline-block;
  border: none;
  text-align: center;
  padding: 7px 20px;
  margin-top: 15px;
  background-color: #d7dae5;
  font-size: 1em;
  color: #393939;
  border-radius: 5px;
  transition: all 0.1s ease;
}
  .secondary-btn:hover {
    cursor: pointer;
    background-color: #457b9d;
    color: #f1faee;
  }

.breadcrumbs {
  margin-bottom: 20px;
}
  .breadcrumbs svg {
    margin: 0px 6px;
  }

.logout {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 5px 10px;
  background-color: #fff;
  border: none;
  color: #8f8f8f;
  z-index: 100;
}

.page {
  display: flex;
  max-width: 100vw;
  /* padding: 0px 25px; */
  padding-left: 0px;
  margin-left: 205px;
  max-height: 100vh;
  overflow: hidden;
  transition: margin-left 0.3s ease;
}

.minimized + .page {
  margin-left: 91px;
}

.page.column {
  flex-wrap: wrap;
}

.v-navbar {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 5px 15px;
  padding-bottom: 250px;
  background-color: #ffffff;  
  backdrop-filter: blur(10px);
  color: #1d3557;
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 175px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  overflow: scroll;
  border-right: solid 1px #e4e4e4;
  transition: width 0.3s ease;
}

.v-navbar.minimized {
  width: 60px;
}

.v-navbar.minimized .nav-tab {
  padding: 11px 0;
  text-align: center;
}

.nav-toggle {
  float: right;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s ease;
  color: #eceef4;
}

.minimized .nav-toggle {
  transform: rotate(180deg);
}

.nav-tab svg {
  margin-right: 10px;
}

.minimized .nav-tab svg {
  margin-right: 0;
}

.v-navbar .nav-tab {
  display: inline-block;
  padding-bottom: 10px;
  text-align: left;
  padding: 11px 10px;
  background-color: none;
  border-radius: 20px;
  margin-bottom: 5px;
}
  .nav-tab svg {
    margin-right: 10px;
  }

  .line-below {
    border-bottom: solid 2px #e9eafb;
    margin: 10px 0px;
  }

  .logo {
    color: #1A1F4A;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    font-size: 1.2em;
  }

  .v-navbar .nav-tab:hover {
    cursor: pointer;
    color: #a8dadc;
    background-color: #1d3557;

  }

  .nav-tab.active {
    /* color: #f1faee; */
    background-color: #e9f2fd;
    /* background: linear-gradient(180deg, rgba(233,234,251,1) 0%, rgba(233,242,253,1) 48%, rgba(247,241,245,1) 100%); */
  }
    .nav-tab.active:hover {
      color: #f1faee;
      background-color: #457b9d;
    }
    
  .nav-tab.archived {
    opacity: 0.7;
    font-style: italic;
  }

  .nav-tab.archived:hover {
    opacity: 1;
  }

  .v-navbar .nav-tab:last-child {
    margin-bottom: 30px;
  }
.page-top-nav {
  width: calc(100vw - 160px);
  display: flex;
  height: 60px;
  background-color: #ffffff;
  border-bottom: solid 1px #e4e4e4;
  border-left: solid 1px #e4e4e4;
  padding-left: 20px;
}
  .page-top-nav .top-nav-tab {
    color: #0a3d62;
    display: inline-block;
    text-align: left;
    padding: 0px 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    padding-top: 8px;
    background-color: #ffffff;
    border-radius: 20px;
  }

  .page-top-nav .top-nav-tab:hover {
    cursor: pointer;
    color: #1d3557;
    background-color: #e9f2fd;
  }

  .page-top-nav .top-nav-tab.active {
    color: #1d3557;
    background-color: #e9f2fd;
  }

.page section {
  display: flex;
  height: 100vh;
}

.wp-title {
  background-color: rgb(69, 38, 38, 0);
  border: none;
  resize: none;
  height: 1.1em;
  width: calc(100% - 160px);
  margin-top: 13px;
  margin-bottom: 0;
  font-size: 1.1em;
  color: #1d3557;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.writing-type-bubble {
  display: inline-block;
  padding: 8px 10px;
  background-color: #eceef4;
  border-radius: 15px;
  color: #457b9d;
  font-weight: 500;
  font-size: 0.8em;
  text-align: left;
}

  .document-area {
    /* margin-left: 30px; */
  }

.output-area {
  width: calc(100vw - 221px);
  /* max-width: 500px; */
  margin-left: 30px;
}

.document-area, .json-container {
  width: 100%; 
  width: 970px;
  max-width: 630px;
   
}
  .document-area .markdown-block, .json-container {
    border: solid 0px gray;
    border-radius: 5px;
    height: 80vh;
    /* padding: 5px 20px; */
    overflow: scroll;
    background-color: #fff;
    margin-left: 30px;
  }

  .markdown-block p {
    margin: 1em 0;
  }

  .document-area .writing-toolbar {
    flex-direction: row;
    justify-content: space-between;
    align-items:first baseline;
    /* margin-bottom: 20px; */
    padding-left: 30px;
    /* background-color: #fff; */
  }

.copy-btn {
  color: #393939;
  display: inline-block;
  border: none;
  border-radius: 20px;
  text-align: center;
  padding: 8px 11px;
  width: 40px;
  margin-right: 15px;
  cursor: pointer;
  background-color: #eceef4;
  font-size: 0.8em;
}
  .copy-btn:hover {
    cursor: pointer;
    background-color: #457b9d;
    color: #f1faee;
  }

  .copy-btn.done {
    background-color: #369617; 
    color: #f1faee;
  }

.L-P-M {
  padding-left: 25px;
}

.noFlex {
  display: block;
}

.toDoPage {
  overflow: scroll;
}

.toDoPage h4 {
  margin-top: 50px;
}

.toDoList {
  display: block;
  margin: 20px 0px;
}

.toDoList .toDoItem {
  display: block;
  margin-bottom: 15px;
}
  .minor-btn {
    display: inline-block;
    border: none;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
    background-color: #fff;
    font-size: 0.9em;
    color: #457b9d;
  }

  .toDoPage input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.3);
  }

  .toDoPage input[type="text"] {
    border: none;
    /* border-bottom: solid 2px #f1faee; */
    /* background-color: #f1faee; */
    font-size: 1em;
    width: 280px;
    padding: 0px;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

  .toDoPage input[type="text"]:focus {
    outline: none;
    border-bottom: solid 2px #457b9d;

  }

  .todo-number {
    display: inline-block;
    width: 13px;  /* Adjust this value based on your needs */
    text-align: center;
    margin-right: 8px;
    color: #666666;
  }

.toDoItem {
    display: flex;
    align-items: center;
    padding: 0px 2px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.toDoItem.active-todo {
    background-color: #e9f2fd;
    position: relative;
    padding: 4px 2px;
}

.toDoItem:focus {
    outline: none;
}

  .draggable {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
  }
  
  .draggable-category {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem;
  }
  
  h2 {
    margin: 0;
  }
  
  .drag-handle {
    border: 2px solid blue;
    background: cornflowerblue;
    padding: 1rem;
    border-radius: 4px;
  }
  
  .category-container {
    width: 90%;
  }
  
  .item {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    background: lightgray;
  }
  
  .cedric-center {
    margin: auto;
    margin-top: 0px;
  }