.list-of-example-types {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-of-example-types .example-type-block, .new-writing-button {
    padding: 30px;
    border: solid 1px #e4e4e4;
    margin-right: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    background-color: #fff;
    width: 250px;
}
    .example-type-block p {
        width: 250px;
    }

.example-type-page {
    display: flex;
    width: calc(100vw - 250px);
}
    .example-type-page .example-form {
        border: solid 1px #e9eafb;
        background-color: #fff;
        padding: 20px;
        margin-right: 20px;
    }

    .example-form h3, .example-form textarea {
        width: 400px;
    }

    .example-form .delete {
        margin-top: 20px;
        padding-top: 10px;
        border-top: solid 2px #e9eafb;
    }

    .example-form textarea {
        height: 100px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 5px;
        width: calc(100% - 35px);
    }

    .example-list {
        max-height: calc(100vh - 250px);
        overflow: scroll;
    }
    
    .example-type-page .example {
        padding: 15px;
        margin-bottom: 10px;
        border: solid 1px #e9eafb;
        background-color: #fff;
    }

    .example-type-page .gray {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 0.9em;
    }